@import "../../styles/variables.scss";

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 40px;
  max-width: 750px;
  width: 100%;

  // background: $light-background;

  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.blur {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  svg {
    transition: 0.3s;
  }

  &:hover {
    svg {
      transform: rotate(360deg);
    }
  }
}

.day {
  .day__temp {
    font-weight: 500;
    font-size: 60px;
    color: $primary-color;
    margin-bottom: 12px;
  }

  .day__name {
    font-size: 25px;
    line-height: 30px;
    // color: $light-color;
    margin-bottom: 12px;
  }

  .img {
    svg {
      width: 53px;
      height: 53px;
    }
    margin-bottom: 12px;
  }

  .day__time,
  .day__city {
    font-size: 15px;
    color: $base-color;
  }

  .day__time {
    margin-bottom: 5px;
  }
}
