@import "../../../../styles/variables.scss";

.ThisDay {
  padding: 20px;
  max-width: 400px;
  width: 100%;
  // background-color: $light-background;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  margin-right: 10px;
}

.top__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  svg {
    max-width: 119px;
    width: 100%;
    min-height: 119px;
  }

  .this__temp {
    font-size: 96px;
    font-weight: 500;
    color: $primary-color;
  }

  .this__day_name {
    font-size: 40px;
  }
}

.bottom__block {
  .this__time,
  .this__city {
    color: $base-color;
    font-size: 25px;
    line-height: 30px;
  }
}

@media screen and (max-width: 479px) {
  .ThisDay {
    margin: 0 auto;
    max-width: 380px;
  }
}
