@import "../../../../../styles/variables.scss";

.card {
  display: flex;
  // flex: 1 1 0;
  margin: 5px;
  flex-direction: column;
  background: $secondary-color;

  border-radius: 10px;
  padding: 10px;
  width: 149px;
  transition: 0.3s all;
  cursor: pointer;

  .day {
    margin-bottom: 7px;
    font-size: 18px;
    // color: $light-color;
  }

  .day__info {
    font-size: 14px;
    margin-bottom: 12px;
    color: $base-color;
  }

  .img {
    margin-bottom: 12px;
  }

  .temp__day {
    font-size: 18px;
    // color: $light-color;
  }

  .temp__night {
    font-size: 13px;
    color: $base-color;
    margin-bottom: 6px;
  }

  .info {
    font-size: 13px;
    color: $base-color;
  }

  &:hover {
    box-shadow: $box-shadow;
  }
}

.cardH {
  display: flex;
  // flex: 1 1 0;
  margin: 5px;
  flex-direction: column;
  background: $secondary-color;

  border-radius: 10px;
  padding: 10px;
  width: 100%;
  max-width: 80px;
  transition: 0.3s all;
  cursor: pointer;

  &__day {
    margin-bottom: 7px;
    font-size: 18px;
    // color: $light-color;
  }

  &__day__info {
    font-size: 14px;
    margin-bottom: 12px;
    color: $base-color;
  }

  &__img {
    margin-bottom: 12px;
  }

  &__temp__day {
    font-size: 18px;
    // color: $light-color;
  }

  &__temp__night {
    font-size: 13px;
    color: $base-color;
    margin-bottom: 6px;
  }

  &__info {
    font-size: 13px;
    color: $base-color;
  }

  &:hover {
    box-shadow: $box-shadow;
  }
}
