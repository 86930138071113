@import "../../../../styles//variables.scss";

.This__Day__Info {
  position: relative;
  padding: 40px;
  max-width: 750px;
  width: 100%;
  background: $light-background;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  z-index: -2;
  margin-left: 10px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .indicator,
  .indicator__name {
    margin-right: 20px;
  }

  .indicator {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 1px 4px 10px -1px rgba(71, 147, 255, 0.2);
    border-radius: 50%;
  }

  .indicator__name,
  .indicator__value {
    font-size: 14px;
  }

  .indicator__name {
    color: $base-color;
    width: 100%;
    max-width: 96px;
  }
}
.cloud__img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.this__day__info__items {
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 479px) {
  .This__Day__Info {
    margin: 20px auto 0 auto;
    max-width: 380px;
    padding: 20px;
  }

  .cloud__img {
    display: none;
  }
}
