@import "../../../../styles/variables.scss";

.days {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  // margin: 0 -5px;
  max-width: 100%;
  background: $light-background;
  box-shadow: $box-shadow;
  border-radius: 0 0 $border-radius $border-radius;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.cancel,
.tab {
  box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
  border-radius: 5px;
  padding: 9px 20px;
  background: $light-background;
  // color:$light-color;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.cancel {
  margin-right: 0;
}

.tabs__wrapper {
  display: flex;
}

.link {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 479px) {
  .tabs {
    display: none;
  }

  .days {
    display: flex;
    justify-content: start;
    border-radius: 10px;
    margin: 0;
    margin-top: 0;
  }
}
