.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media screen and (max-width: 479px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
