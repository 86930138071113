.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 30px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.logo,
.change_theme {
  margin-right: 20px;
  cursor: pointer;
}

.logo {
  svg {
    width: 65px;
    height: 65px;
  }
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 25px;
  color: #4793ff;
}

.text__field {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

@media screen and (max-width: 479px) {
  .logo,
  .title,
  .select {
    display: none;
  }

  .text__field {
    margin-right: 15px;
  }

  .header {
    display: block;
  }
}
